<template>
  <div class="text-container">
    Durch unsere intensive Arbeit im Apothekenmarkt und durch Netzwerke kennen
    wir potentielle Käufer für Ihre Apotheke. Wenden Sie sich gerne
    vertrauensvoll an uns.
  </div>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Wir finden potentielle Käufer für Ihre Apotheke. Profitieren Sie von unserem Netzwerk!",
      },
    ],
  },
};
</script>